export const luxonZoneNames = {
    "UTC-12": "UTC-12:00",
    "UTC-11": "UTC-11:00",
    "UTC-10": "UTC-10:00",
    "UTC-9:30": "UTC-09:30",
    "UTC-9": "UTC-09:00",
    "UTC-8": "UTC-08:00",
    "UTC-7": "UTC-07:00",
    "UTC-6": "UTC-06:00",
    "UTC-5": "UTC-05:00",
    "UTC-4": "UTC-04:00",
    "UTC-3:30": "UTC-03:30",
    "UTC-3": "UTC-03:00",
    "UTC-2": "UTC-02:00",
    "UTC-1": "UTC-01:00",
    "UTC": "UTC+00:00",
    "UTC+1": "UTC+01:00",
    "UTC+2": "UTC+02:00",
    "UTC+3": "UTC+03:00",
    "UTC+3:30": "UTC+03:30",
    "UTC+4": "UTC+04:00",
    "UTC+4:30": "UTC+04:30",
    "UTC+5": "UTC+05:00",
    "UTC+5:30": "UTC+05:30",
    "UTC+5:45": "UTC+05:45",
    "UTC+6": "UTC+06:00",
    "UTC+6:30": "UTC+06:30",
    "UTC+7": "UTC+07:00",
    "UTC+8": "UTC+08:00",
    "UTC+8:45": "UTC+08:45",
    "UTC+9": "UTC+09:00",
    "UTC+9:30": "UTC+09:30",
    "UTC+10": "UTC+10:00",
    "UTC+10:30": "UTC+10:30",
    "UTC+11": "UTC+11:00",
    "UTC+12": "UTC+12:00",
    "UTC+12:45": "UTC+12:45",
    "UTC+13": "UTC+13:00",
    "UTC+14": "UTC+14:00",
}

export default [
    "UTC-12:00",
    "UTC-11:00",
    "UTC-10:00",
    "UTC-09:30",
    "UTC-09:00",
    "UTC-08:00",
    "UTC-07:00",
    "UTC-06:00",
    "UTC-05:00",
    "UTC-04:00",
    "UTC-03:30",
    "UTC-03:00",
    "UTC-02:00",
    "UTC-01:00",
    "UTC+00:00",
    "UTC+01:00",
    "UTC+02:00",
    "UTC+03:00",
    "UTC+03:30",
    "UTC+04:00",
    "UTC+04:30",
    "UTC+05:00",
    "UTC+05:30",
    "UTC+05:45",
    "UTC+06:00",
    "UTC+06:30",
    "UTC+07:00",
    "UTC+08:00",
    "UTC+08:45",
    "UTC+09:00",
    "UTC+09:30",
    "UTC+10:00",
    "UTC+10:30",
    "UTC+11:00",
    "UTC+12:00",
    "UTC+12:45",
    "UTC+13:00",
    "UTC+14:00",
]